<template>
    <div class="container">
        <div class="recharge-container">
            <h2>产品定价</h2>
            <div class="user-container">
                <span class="user-container-account">充值帐号：{{ account }}</span>
                <span class="user-container-balance">当前余额：{{ balance }}词</span>
            </div>
            <el-card class="recharge-card">
                <el-tabs
                    v-model="selectedTab"
                    class="recharge-card-tabs"
                    type="border-card"
                    @tab-change="consoleTab"
                >
                    <el-tab-pane label="降AI率套餐包" style="height: 100%">
                        <component :is="GoodsList"></component>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="写作辅导" style="height: 100%">
                        <component :is="CustomService"></component>
                    </el-tab-pane> -->
                    <!--                <el-tab-pane label="Role">Role</el-tab-pane>-->
                    <!--                <el-tab-pane label="Task">Task</el-tab-pane>-->
                </el-tabs>
            </el-card>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import GoodsList from '@/components/GoodsList.vue'
import CustomService from '@/components/CustomService.vue'
import { removeToken } from '@/stores/authStore'

const account = ref(null)
const balance = ref(0)
const selectedTab = ref(null)

const consoleTab = () => {
    console.log(selectedTab.value)
}

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        account.value = response.data.userInfo.phone || response.data.userInfo.email
        balance.value = response.data.userInfo.balance
    } catch (error) {
        if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
            removeToken()
            alertService.showError('用户未登录')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

onMounted(() => {
    fetchDataUserInfo()
    // fetchProduct()
})
</script>

<style lang="scss">
.container {
    width: max(100%, 1500px);
    display: flex;
    justify-content: center;
    height: max(100%, 770px);

    .recharge-container {
        width: 1440px;
        //width: 100%;
        //height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .user-container {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            .user-container-account {
                margin-right: 30px;
            }
        }

        .recharge-card {
            width: 100%;
            height: 80%;

            .el-card__body {
                height: 90%;
            }

            .recharge-card-tabs {
                height: 100%;

                .recharge-card-tabs {
                    --el-tab-item-hover-color: #ff7043;
                }
            }
        }
    }
}
</style>
