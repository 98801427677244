<template>
    <div class="nav-container">
        <div class="head-nav">
            <div class="logo">
                <img
                    alt="ZeroAI Logo"
                    class="logo-link"
                    height="40px"
                    src="../../public/logo1.svg"
                    width="40px"
                    @click="gotoHome"
                />
            </div>
            <ul class="head-nav-list">
                <li class="head-nav-detail" @click.prevent="gotoHome">降AI</li>
                <li class="head-nav-detail" @click.prevent="gotoRecharge">充值</li>
                <li class="head-nav-detail" @click.prevent="gotoUser">历史记录</li>
                <li class="head-nav-detail" @click.prevent="gotoFaq">常见问题</li>
                <!-- <li class="head-nav-detail" @click.prevent="showRedeemDialog">兑换码</li> -->
                <!-- <li class="head-nav-detail" @click.prevent="showInviteCodeDialog">邀请码</li> -->
                <!-- <li class="head-nav-detail">
                    <a class="register-tip-link" href="/faq.html" target="_blank">FAQ</a>
                </li> -->
            </ul>
        </div>
        <div class="select-login">
            <el-button v-show="!isLoggedIn" class="select-login-btn" round @click="login"
                >注册/登录</el-button
            >
            <el-popover placement="bottom-end" trigger="click" width="120">
                <template #reference>
                    <el-avatar v-show="isLoggedIn" class="login-avatar-icon" size="30">
                        <el-icon style="font-size: 25px">
                            <UserFilled />
                        </el-icon>
                    </el-avatar>
                </template>
                <div class="login-list">
                    <div class="login-detail" @click="gotoUser">账户信息</div>
                    <div class="login-detail" @click="gotoUpdatePassword">修改密码</div>
                    <div class="login-detail" @click="logout">退出登录</div>
                </div>
            </el-popover>
        </div>
        <RedeemDialog :visible="isRedeemDialogVisible" @update:visible="setRedeemDialogVisible" />
        <InviteCodeDialog
            :visible="isInviteCodeDialogVisible"
            @update:visible="setInviteCodeDialogVisible"
        />
    </div>
    <div class="banner" @click="showInviteCodeDialog">
        <img src="@/assets/happy.svg" alt="Promotion Icon" class="banner-icon" />
        限时推广优惠活动：邀请会员与被邀请会员均可获得最高1000词免费额度【点击查看详情】
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import RedeemDialog from '@/components/Redeem.vue'
import InviteCodeDialog from '@/components/InviteCode.vue'
import { UserFilled } from '@element-plus/icons-vue'
import { removeToken, useAuthStore } from '@/stores/authStore'

const router = useRouter()
const isRedeemDialogVisible = ref(false)
const isInviteCodeDialogVisible = ref(false)
const authStore = useAuthStore()
const isLoggedIn = ref(authStore.isLoggedIn)
// 监听 isLoggedIn 的变化
watch(
    () => authStore.isLoggedIn,
    (newValue) => {
        isLoggedIn.value = newValue
    },
    { immediate: true }
)
function login() {
    removeToken()
    router.push('/login')
}
function logout() {
    removeToken()
    window.location.reload()
}
const gotoUpdatePassword = () => {
    router.push('/change-password')
}
const gotoRecharge = () => {
    router.push('/recharge')
}
const gotoHome = () => {
    router.push('/')
}
const showRedeemDialog = () => {
    isRedeemDialogVisible.value = true
}
const setRedeemDialogVisible = (visible) => {
    isRedeemDialogVisible.value = visible
}
const showInviteCodeDialog = () => {
    isInviteCodeDialogVisible.value = true
}
const setInviteCodeDialogVisible = (visible) => {
    isInviteCodeDialogVisible.value = visible
}
const gotoUser = () => {
    router.push('/user')
}
const gotoFaq = () => {
    window.open('https://faq.zeroai.top')
}
</script>

<style lang="scss" scoped>
.nav-container {
    border-bottom: 2px solid #002f5a;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-nav {
        display: flex;
        flex-direction: row;

        .logo {
            .logo-link {
                cursor: pointer; /* 默认为手形指针 */
            }
        }

        .head-nav-list {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 0;

            .head-nav-detail {
                display: block;
                padding: 10px 20px;
                color: #002f5a;
                text-decoration: none;
                white-space: nowrap;
                cursor: pointer; /* 默认为手形指针 */
            }

            :hover {
                color: #1a8cff;
            }
        }
    }

    .select-login {
        .select-login-btn {
            background-color: #032a50;
            color: #fff;
        }
    }

    .login-avatar-icon {
        cursor: pointer; /* 默认为手形指针 */
    }
}
.banner {
    background: linear-gradient(to right, #0096ff, #09e0c4);
    color: white;
    text-align: center;
    font-size: 18px;
    width: 100%; /* 添加这一行 */
    height: 40px;
    line-height: 40px; /* 添加这一行，使文本垂直居中 */
    font-weight: bold;
    cursor: pointer;
    .banner-icon {
        width: 24px; /* 设置 SVG 图片的宽度 */
        height: 24px; /* 设置 SVG 图片的高度 */
        margin-right: 10px; /* 在图片和文本之间添加一些间距 */
        transform: translateY(-50%); /* 将图标向上移动自身高度的一半 */
        position: relative; /* 确保图标相对定位 */
        top: 35%; /* 将图标顶部定位在父容器的中间 */
    }
}
.el-popper {
    .login-list {
        .login-detail {
            cursor: pointer; /* 默认为手形指针 */
            font-size: 16px;
            padding: 10px 20px;
            //font-weight: bold;
        }
    }
}

.el-popper .login-list .login-detail:hover {
    background-color: #1a8cff;
    color: #fff;
    border-radius: 15px;
}
</style>
