<template>
    <div class="login">
        <div class="login-container">
            <div class="login-main">
                <h2 class="title" style="margin-top: 0">登录</h2>
                <div class="login-pwd">
                    <el-input
                        v-model="login.account"
                        placeholder="手机号(+86)/邮箱"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend> 账号 </template>
                    </el-input>

                    <el-input
                        v-model="login.password"
                        placeholder="输入密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend> 密码 </template>
                        <template v-slot:suffix>
                            <p class="forget-pwd" @click="gotoForgetPwd">忘记密码？</p>
                        </template>
                    </el-input>

                    <!-- 新增验证码输入框 -->
                    <!-- <div class="captcha" style="width: 100%"> -->
                    <el-input
                        v-model="login.captcha"
                        style="width: 350px; height: 40px"
                        placeholder="输入验证码"
                    >
                        <template v-slot:prepend> 验证码 </template>
                        <template v-slot:suffix>
                            <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="点击刷新"
                                placement="right"
                            >
                                <span class="captcha-image" @click="changeCaptcha">
                                    {{ captcha }}
                                </span>
                            </el-tooltip>
                        </template>
                    </el-input>
                </div>
                <div class="login-select-btn">
                    <el-button
                        class="register-btn"
                        style="width: 150px"
                        @click="gotoRegisterByPhone"
                        >注册</el-button
                    >
                    <el-button class="login-btn" style="width: 150px" @click="loginButton"
                        >登录</el-button
                    >
                </div>
                <span class="login-remind">
                    新用户建议使用手机号注册登录，可享受免费额度体验，邮箱注册登录不提供该免费额度。
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { alertService } from '@/utils/alertService.js'
import { apiService } from '@/api/Api.js'
import { useRouter } from 'vue-router'
import verifyPhone from '@/utils/verifyPhone'
import verifyMail from '@/utils/verifyMail'
import { removeToken, setToken } from '@/stores/authStore'

const login = reactive({
    accoun: '',
    password: '',
    captcha: ''
})

// 验证码相关
const captcha = ref(generateCaptcha())
function generateCaptcha() {
    let chars = '0123456789'
    let result = ''
    for (let i = 0; i < 4; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return result
}
function changeCaptcha() {
    captcha.value = generateCaptcha()
}

const router = useRouter()
const loginButton = async () => {
    if (login.password.length < 6) {
        alertService.showError('密码长度不能小于6位')
        changeCaptcha()
        return
    }
    if (login.account.length === 0) {
        alertService.showError('请输入账号')
        changeCaptcha()
        return
    }
    if (login.captcha !== captcha.value) {
        alertService.showError('验证码错误')
        changeCaptcha()
        return
    }

    const requestParams = {
        password: login.password
    }
    if (verifyPhone(login.account)) {
        requestParams.phone = login.account
    } else if (verifyMail(login.account)) {
        requestParams.email = login.account
    } else {
        alertService.showError('请输入正确的手机号或邮箱')
        return
    }
    try {
        const response = await apiService.login(requestParams)
        // console.log(response)
        // 检查响应状态码和内容
        if (response.status === 200 && response.data.userInfo.token) {
            setToken(response.data.userInfo.token, response.data.userInfo.inviteCode)
            router.push('/')
        } else {
            alertService.showError('用户名或密码错误')
        }
    } catch (error) {
        if (error.response?.data?.code === 401) {
            removeToken()
            alertService.showError('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
        changeCaptcha()
    }
}

const gotoForgetPwd = () => {
    // router.push('/retrieve-password').then(() => {
    //     window.location.reload()
    // })
    router.push('/retrieve-password')
}

const gotoRegisterByPhone = () => {
    // router.push('/register/phone').then(() => {
    //     window.location.reload()
    // })
    router.push('/register/phone')
}

// const loginAliPayBtn = async () => {
//     // 跳转到支付宝支付页面
//     const requestParams = {}
//     try {
//         const response = await apiService.alipayLoginUserIngoAuth(requestParams)
//         const url = response.data.url
//         console.log(url)
//         // 使用window.open方法在新窗口中打开url
//         window.open(url, '_blank')
//     } catch (error) {
//         alertService.showError(error.response?.data?.message || '发生未知错误！')
//     }
// }
</script>

<style lang="scss">
.login {
    display: flex;
    width: max(100%, 800px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max(100%, 400px);

    .login-container {
        width: 600px;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        // justify-content: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;

        .login-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            .login-pwd {
                display: flex;
                flex-direction: column;
                .forget-pwd {
                    color: #002f5a;
                    cursor: pointer;

                    :hover {
                        color: #1a8cff;
                    }
                }

                .captcha-image {
                    display: inline-block;
                    width: 100px;
                    line-height: 40px;
                    text-align: center;
                    // background-color: #f0f0f0;
                    cursor: pointer;
                    user-select: none;
                }
            }

            .login-select-btn {
                // width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;

                .login-btn {
                    background: #002f5a;
                    color: #fff;
                }
            }
        }

        .login-other {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;

            .login-other-title {
                font-size: 14px;
                color: #002f5a;
            }

            .login-other-list {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .login-other-item {
                    .login-alipay-img {
                        cursor: pointer; /* 默认为手形指针 */
                    }
                }
            }
        }

        .login-remind {
            font-size: 13px;
            color: #ff0000;
        }
    }
}

.el-input el-input__wapper el-input__suffix el-input__suffix-inner forget-pwd:hover {
    color: #fff;
}
</style>
