<template>
    <div class="register">
        <!--        <div class="navbar-wrapper">-->
        <!--            <Navbar />-->
        <!--        </div>-->
        <div class="register-container">
            <div class="register-main">
                <h1 class="title">邮箱注册</h1>

                <div class="register-login-div">
                    <span>
                        已有账号?
                        <RouterLink class="login-link" to="/login">登录</RouterLink>
                    </span>
                </div>

                <div class="register-pwd">
                    <el-input
                        v-model="register.email"
                        placeholder="输入邮箱"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">邮箱</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="register.password"
                        placeholder="输入密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">新密码</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="register.confirmPassword"
                        placeholder="再次输入密码"
                        show-password
                        style="width: 350px; height: 40px"
                        type="password"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">确认密码</span>
                        </template>
                    </el-input>
                    <el-input
                        v-model="register.verificationCode"
                        placeholder="输入验证码"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">验证码</span>
                        </template>
                        <template v-slot:suffix>
                            <el-button
                                :disabled="!canGetCode"
                                class="get-verify-code-btn"
                                link
                                @click="getVerificationCode"
                            >
                                {{ buttonText }}
                            </el-button>
                        </template>
                    </el-input>
                    <el-input
                        v-model="register.inviteCode"
                        placeholder="邀请码(选填)"
                        style="width: 350px; height: 40px"
                    >
                        <template v-slot:prepend>
                            <span class="input-prepend">邀请码</span>
                        </template>
                    </el-input>
                </div>
                <div>
                    <el-button
                        class="register-pwd-confirm-button"
                        style="width: 150px"
                        @click="gotoRegisterByPhone"
                    >
                        手机号注册
                    </el-button>
                    <el-button
                        class="register-pwd-confirm-button"
                        style="width: 150px"
                        @click="registerButton"
                    >
                        注册
                    </el-button>
                </div>
                <div class="register-tips">
                    <span class="register-tip-1">
                        注册即代表同意
                        <a class="register-tip-link" href="/licence.html" target="_blank"
                            >用户协议</a
                        >
                        和
                        <a class="register-tip-link" href="/privacy-pc.html" target="_blank"
                            >隐私政策</a
                        >
                    </span>
                    <span class="register-tip-2">
                        新用户建议使用手机号注册登录，可享受免费额度体验，邮箱注册登录不提供该免费额度。
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'
import verifyMail from '@/utils/verifyMail'
import { removeToken } from '@/stores/authStore'

const register = reactive({
    email: '',
    confirmPassword: '',
    verificationCode: '',
    inviteCode: ''
})

// 验证码时间
const canGetCode = ref(true)
const buttonText = ref('获取验证码')
let timer = null
const getVerificationCode = async () => {
    if (timer) return

    canGetCode.value = false
    let countdown = 60
    buttonText.value = `${countdown}秒`

    timer = setInterval(() => {
        countdown -= 1
        buttonText.value = `${countdown}秒`
        if (countdown <= 0) {
            clearInterval(timer)
            timer = null
            canGetCode.value = true
            buttonText.value = '获取验证码'
        }
    }, 1000)

    // console.log('发送验证码到手机')
    const requestParams = {
        email: register.email,
        type: 1
    }
    try {
        await apiService.generateVerifyCode(requestParams)
        alertService.showSuccess('验证码已发送')
    } catch (error) {
        if (error.response?.data?.code === 401) {
            removeToken()
            alertService.showError('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

// 注册
const router = useRouter()
const registerButton = async () => {
    // 校验邮箱格式
    if (!verifyMail(register.email)) {
        alertService.showError('请输入有效的邮箱地址')
        return
    }
    // 校验确认密码
    if (register.password !== register.confirmPassword) {
        alertService.showError('两次密码不一致')
        return
    }
    if (register.password < 6) {
        alertService.showError('密码长度不能小于6位')
        return
    }
    // 参数
    const requestParams = {
        email: register.email,
        password: register.password,
        verifyCode: register.verificationCode,
        inviteCode: register.inviteCode !== '' ? register.inviteCode : null
    }

    try {
        await apiService.register(requestParams)
        alertService.showSuccess('账号注册成功')
        // 跳转登录界面
        // router.push('/login').then(() => {
        //     window.location.reload()
        // })
        router.push('/login')
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}

const gotoRegisterByPhone = () => {
    router.push('/register/phone')
}
</script>

<style>
.register {
    display: flex;
    width: max(100%, 800px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max(100%, 600px);

    .register-container {
        width: 600px;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;

        .register-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .login-link {
                color: #1a8cff;
            }

            .register-pwd {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .input-prepend {
                    display: inline-block;
                    width: 80px; /* 根据最长的前缀文字调整宽度 */
                    text-align: center;
                }

                .get-verify-code-btn {
                    color: #002f5a;
                    cursor: pointer;
                }
            }

            .register-pwd-confirm-button {
                margin-top: 20px;
                background: #002f5a;
                color: #fff;
                cursor: pointer;
            }

            .register-tips {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 20px;

                .register-tip-1 {
                    margin: 5px 0;
                    font-size: 16px;
                    color: #666;

                    .register-tip-link {
                        color: #1a8cff;
                    }
                }
                .register-tip-2 {
                    font-size: 13px;
                    color: #ff0000;
                }
            }
        }
    }
}
</style>
