<template>
    <el-dialog
        class="dialog-overlay"
        v-model="isVisible"
        :show-close="false"
        destroy-on-close
        :before-close="close"
        draggable
    >
        <template #header="{ close }">
            <div class="my-header">
                <el-button @click="close" class="my-header-close-btn" circle color="#fff">
                    <el-icon size="15" color="#0096ff"><Close /></el-icon>
                </el-button>
            </div>
        </template>

        <div class="dialog-content">
            <div class="dialog-content-title">
                <div class="dialog-content-title-text">你的邀请码</div>
            </div>
            <div class="dialog-content-body">
                <div class="dialog-content-body-invite-code">
                    <div></div>
                    <div class="dialog-content-body-invite-code-content">{{ inviteCode }}</div>
                    <el-icon
                        size="15"
                        color="#0096ff"
                        class="dialog-content-body-invite-code-copy-btn-icon"
                        @click="copyInviteCode"
                    >
                        <CopyDocument />
                    </el-icon>
                </div>
            </div>
            <div class="dialog-content-tip">
                <div class="dialog-content-tip-text">
                    <el-text>
                        充值任意金额即为网站会员，<strong>邀请会员与被邀请会员均可获得被邀请方充值额度的10%。</strong>例如，被邀请方充值10000词额度，双方均可获得1000词附加额度。
                    </el-text>
                </div>
            </div>
            <div class="dialog-content-invite-record">
                <div class="dialog-content-invite-record-title">
                    <div class="dialog-content-invite-record-title-text">邀请记录</div>
                </div>
                <div class="dialog-content-invite-record-body">
                    <el-table
                        class="dialog-content-invite-record-body-table"
                        :data="invitationInfo"
                        style="width: 100%; height: 150px"
                    >
                        <el-table-column prop="invitee" label="被邀请人" width="250" />
                        <el-table-column prop="inviterBonus" label="邀请奖励" width="150" />
                    </el-table>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { ElLoading } from 'element-plus'
import { ElButton, ElDialog, ElMessage } from 'element-plus'
import { Close, CopyDocument } from '@element-plus/icons-vue'
const props = defineProps({ visible: Boolean })
const emit = defineEmits(['update:visible'])
const isVisible = ref(props.visible)
const inviteCode = ref('')
const invitationInfo = ref([{ invitee: '', inviterBonus: '' }]) // 邀请记录
const bonusTip = ref('暂未购买套餐')

watch(
    () => props.visible,
    (newVal) => {
        isVisible.value = newVal
        if (newVal) {
            inviteCode.value = localStorage.getItem('inviteCode')
            const token = localStorage.getItem('token')
            if (token) {
                if (!inviteCode.value && !isNaN(inviteCode.value)) {
                    getInviteCode()
                }
                getInviteRecord() // 在 token 存在时调用 getInviteRecord 函数
            }
        }
    }
)

async function getInviteRecord() {
    try {
        const response = await apiService.getInviteRecord()
        // 去掉数组第一个元素
        const tmp = []
        response.data.invitations.forEach((invitation) => {
            console.log('phone:', invitation.inviteePhone !== undefined)
            const invitee =
                invitation.inviteePhone !== undefined
                    ? invitation.inviteePhone
                    : invitation.inviteeEmail
            const inviterBonus =
                invitation.inviterBonus !== undefined ? invitation.inviterBonus : bonusTip.value
            tmp.push({
                invitee: invitee,
                inviterBonus: inviterBonus
            })
        })
        invitationInfo.value = tmp
        console.log(response.data)
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}

const close = () => {
    isVisible.value = false
    emit('update:visible', false)
    inviteCode.value = ''
}

const getInviteCode = async () => {
    const loading = ElLoading.service({
        lock: true,
        text: '正在获取邀请码，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    try {
        // 假设从后端获取邀请码的API调用
        const response = await apiService.getInviteCode()
        inviteCode.value = response.data.inviteCode
    } catch (error) {
        alertService.showError('获取邀请码失败，请重试')
    } finally {
        loading.close()
    }
}
// copyInviteCode 复制邀请码
const copyInviteCode = () => {
    // navigator.clipboard.writeText(inviteCode.value).then(() => {
    //     ElMessage({
    //         message: '邀请码复制成功',
    //         type: 'success'
    //     })
    // })
    if (navigator.clipboard) {
        navigator.clipboard
            .writeText(inviteCode.value)
            .then(() => {
                ElMessage({
                    message: '邀请码复制成功',
                    type: 'success'
                })
            })
            .catch((err) => {
                console.error('无法复制邀请码: ', err)
                ElMessage({
                    message: '邀请码复制失败',
                    type: 'error'
                })
            })
    } else {
        // 备选方案：使用 document.execCommand
        const textarea = document.createElement('textarea')
        textarea.value = inviteCode.value
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        ElMessage({
            message: '邀请码复制成功',
            type: 'success'
        })
    }
}
</script>

<style scoped>
.dialog-overlay {
    .my-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
    }
    .dialog-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .dialog-content-title {
            width: 100%;
            .dialog-content-title-text {
                font-size: 30px;
                font-weight: bold;
                color: white;
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .dialog-content-body {
            width: 80%; /* 根据需要调整宽度 */
            height: 80px; /* 根据需要调整高度 */
            /* border: 1px solid #ccc; /* 边框样式 */
            padding: 20px; /* 内边距 */
            background-color: rgba(255, 255, 255, 0.2); /* 半透明背景色，根据需要调整透明度 */
            border-radius: 20px; /* 您可以根据需要调整圆弧的半径 */
            margin-bottom: 20px;
            .dialog-content-body-invite-code {
                height: 40px;
                border: 1px solid #ccc; /* 空白框边框样式 */
                padding: 20px; /* 空白框内边距 */
                background-color: #fff;
                border-radius: 20px; /* 您可以根据需要调整圆弧的半径 */
                overflow: hidden; /* 确保内容不会超出圆角边界 */
                display: flex;
                justify-content: space-between;
                align-items: center;
                .dialog-content-body-invite-code-content {
                    font-size: 30px;
                    text-align: center;
                    background: linear-gradient(
                        to right,
                        #0096ff,
                        #09e0c4
                    ); /* 设置背景色为从左到右的渐变色 */
                    -webkit-background-clip: text; /* 兼容旧版本的浏览器，使背景色只应用于文本内容 */
                    background-clip: text; /* 使背景色只应用于文本内容 */
                    color: transparent; /* 设置文本颜色为透明，以便显示背景色 */
                }
                .dialog-content-body-invite-code-copy-btn-icon {
                    cursor: pointer;
                    transition: color 0.3s ease; /* 添加过渡效果 */
                    &:hover {
                        color: #0365ab; /* 鼠标悬停时的颜色 */
                        font-weight: bold;
                    }
                }
            }
        }
        .dialog-content-tip {
            width: 80%;
            height: 60px; /* 根据需要调整高度 */
            border: 1px dashed #ccc; /* 边框样式 */
            padding: 20px; /* 内边距 */
            background-color: #fff; /* 半透明背景色，根据需要调整透明度 */
            border-radius: 20px; /* 您可以根据需要调整圆弧的半径 */
            margin-bottom: 20px;
        }
        .dialog-content-invite-record {
            width: 80%;
            /* padding: 20px; /* 内边距 */
            /* background-color: rgba(255, 255, 255, 0.2); /* 半透明背景色，根据需要调整透明度 */
            border-radius: 20px; /* 您可以根据需要调整圆弧的半径 */
            /* margin-bottom: 20px; */
            .dialog-content-invite-record-title {
                .dialog-content-invite-record-title-text {
                    font-size: 20px;
                    font-weight: bold;
                    color: white;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            .dialog-content-invite-record-body {
                width: 100%;
                border-radius: 10px; /* 圆角 */

                padding: 10px; /* 内边距 */
            }
        }
    }
}
</style>
