<template>
    <div class="good-list-container">
        <div class="activity-column">
            <div class="activity-container">
                <div class="activity-main">
                    <div class="activity-content">
                        【售后】购买套餐后，若降AI效果不满意，可联系客服咨询售后服务。
                    </div>
                </div>
            </div>
        </div>
        <div class="goods-list">
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    250词
                    <span class="good-detail-feature">免费</span>
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <span class="goods-price">{{ goodListPrice[0] }}</span>
                    <!--                <span class="expiration-date">CNY</span>-->
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    disabled
                >
                    免费体验
                </el-button>
                <ul class="good-detail-introduce-list">
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        新用户免费获得250词体验额度
                    </li>
                    <li class="good-detail-introduce-bold-activity">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        活动仅限手机号注册用户
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    600词
                    <!--                <span class="good-detail-feature">免费体验</span>-->
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <span class="goods-price">{{ goodListPrice[1] }}</span>
                    <!--                <span class="expiration-date">CNY</span>-->
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    @click="doRecharge(100003, goodListPrice[1], '套餐一_100003')"
                    >立即购买
                </el-button>
                <ul class="good-detail-introduce-list">
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        单词0.13元/词
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    1000词
                    <span class="good-detail-feature">77折</span>
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <span class="goods-price">{{ goodListPrice[2] }}</span>
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    @click="doRecharge(100004, goodListPrice[2], '套餐二_100004')"
                    >立即购买
                </el-button>
                <ul class="good-detail-introduce-list">
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        单价0.099元/词
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    2000词
                    <span class="good-detail-feature">71折</span>
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <span class="goods-price">{{ goodListPrice[3] }}</span>
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    @click="doRecharge(100005, goodListPrice[3], '套餐三_100005')"
                    >立即购买
                </el-button>
                <ul class="good-detail-introduce-list">
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        单价0.094元/词
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    5000词
                    <span class="good-detail-feature">66折</span>
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <!-- <span class="goods-price-origin" style="text-decoration: line-through">
                        538.88
                    </span> -->
                    <span class="goods-price">
                        {{ goodListPrice[4] }}
                    </span>
                    <!--                <span class="expiration-date">CNY</span>-->
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    @click="doRecharge(100006, goodListPrice[4], '套餐四_100006')"
                    >立即购买
                </el-button>
                <ul class="good-detail-introduce-list">
                    <!-- <li class="good-detail-introduce-bold-activity">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        黑五限时折扣，单价0.089元/词
                    </li> -->
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        单价0.089元/词
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-card class="good-detail" shadow="never" style="height: 100%; overflow-y: auto">
                <h2 class="good-detail-title">
                    10000词
                    <span class="good-detail-feature">58折</span>
                </h2>
                <p class="good-detail-info">
                    <span class="currency-type">¥ </span>
                    <!-- <span class="goods-price-origin" style="text-decoration: line-through"
                        >998.88</span
                    > -->
                    <span class="goods-price">{{ goodListPrice[5] }}</span>
                    <!--                <span class="expiration-date">CNY</span>-->
                </p>
                <el-button
                    class="buy-now"
                    round
                    style="width: 100%; font-size: 20px"
                    type="danger"
                    @click="doRecharge(100007, goodListPrice[5], '套餐五_100007')"
                    >立即购买
                </el-button>
                <ul class="good-detail-introduce-list">
                    <!-- <li class="good-detail-introduce-bold-activity">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        黑五限时折扣，单价0.084元/词
                    </li> -->
                    <li class="good-detail-introduce-bold">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        单价0.084元/词
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        有效降低AI率至0%
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        保障原文的连贯性、逻辑性和可读性、不改变文章原意
                    </li>
                    <li class="good-detail-introduce">
                        <el-icon color="#409efc">
                            <Promotion />
                        </el-icon>
                        智能识别修改拼写和语法错误
                    </li>
                </ul>
            </el-card>
            <el-dialog
                v-model="qrcodeVisible"
                :show-close="false"
                :style="{ height: qrcodeSize }"
                :width="qrcodeSize - 15"
                center
                class="qrcode-dialog"
                draggable
            >
                <el-image
                    :fit="'fill'"
                    :src="customerServiceUrl"
                    class="custom-service-qrcode"
                    style="width: 300px; height: 300px"
                    visiable="false"
                />
            </el-dialog>
        </div>
        <div class="goods-remind">
            <el-dialog
                v-model="goodsReminder"
                title="如未弹出支付窗口，请按以下步骤启用弹窗："
                width="500"
                draggable
                destroy-on-close
            >
                <p>1. 在浏览器地址栏的右侧或左侧寻找“弹窗已被阻止”的提示信息。</p>
                <p>2. 点击提示，并选择“允许弹窗”。</p>
                <p>3. 刷新页面以确保设置生效。</p>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button type="primary" @click="goodsReminder = false"> 确认 </el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { Promotion } from '@element-plus/icons-vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import customerServiceUrl from '@/assets/WechatIMG17.jpg'
import { removeToken } from '@/stores/authStore'

const qrcodeVisible = ref(false)
const qrcodeSize = 350
const goodListPrice = [0, 79.88, 99.88, 188.88, 448.88, 848.88]
// const goodListPrice = [0, 0.01, 0.01, 0.01, 0.01, 0.01]
const goodsReminder = ref(false)
function showCRCode() {
    qrcodeVisible.value = true
}

async function doRecharge(productId, rechargeAmount, subject) {
    goodsReminder.value = true
    const requestParams = {
        productId: productId,
        totalAmount: String(rechargeAmount),
        subject: subject
    }
    try {
        const response = await apiService.createPaymentLink(requestParams)
        const url = response.data.paymentUrl
        // 使用window.open方法在新窗口中打开url
        window.open(url, '_blank')
    } catch (error) {
        if (error.response?.data?.code === 401) {
            removeToken()
            alertService.showError('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}
</script>
<style lang="scss">
.good-list-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .activity-column {
        padding: 0 2% 0 2%;
        width: 100%;
        background-color: #ffffff; /* 背景颜色 */
        flex-direction: column; /* 修改为列方向布局 */
        margin-bottom: 20px;

        //.el-carousel__button {
        //    background-color: #ff9900;
        //}
        .el-carousel__ .tip-content {
            font-size: 16px;
            color: #e70b37; /* 字体颜色 */
        }

        .activity-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .activity-main {
                display: flex;
                flex-direction: column;

                .activity-content {
                    font-size: 16px;
                    color: #995700; /* 字体颜色 */
                    text-align: left;
                }
            }
        }

        //
        //.highlight {
        //    font-weight: bold;
        //    color: #ff6f00; /* 高亮颜色 */
        //}
    }

    .goods-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 100%;

        .good-detail {
            flex: 1;
            margin-left: 3px;
            margin-right: 3px;

            //.el-card__body {
            //    overflow-y: auto;
            //}

            .good-detail-feature {
                display: inline-block;
                background-color: rgba(255, 99, 71, 0.1); /* 浅色背景 */
                color: #ff6347; /* 字体颜色 */
                padding: 5px 15px; /* 内边距 */
                border-radius: 20px; /* 圆角 */
                font-size: 14px; /* 字体大小 */
                font-weight: bold; /* 字体加粗 */
            }

            .good-detail-info {
                .currency-type {
                    font-size: 14px;
                    color: #ff614f;
                    font-weight: bold;
                }

                .goods-price {
                    font-size: 20px;
                    color: #ff614f;
                    font-weight: bold;
                }

                .expiration-date {
                    font-size: 12px;
                    color: #8b7373;
                    font-weight: bold;
                }
            }

            .good-detail-introduce-list {
                padding-left: 0;
                padding-right: 0;
                overflow-y: auto;
                padding-bottom: 50px; /* 根据需要调整数值 */

                .good-detail-introduce,
                .good-detail-introduce-bold,
                .good-detail-introduce-bold-activity {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px; /* 间距调整 */
                    font-size: 14px;
                    letter-spacing: 1px;
                    font-family: '微软雅黑';

                    .el-icon {
                        margin-right: 10px; /* 图标和文本的间距 */
                    }
                }
            }

            .good-detail-introduce-bold-activity {
                color: #ff614f;
                font-weight: bold;
            }

            .good-detail-introduce-bold {
                font-weight: bold;
            }
            .buy-now:disabled {
                background-color: var(--el-color-danger);
            }
        }
    }
}
</style>
